import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import store from '../../../../../Redux/store';
import { get_customer_credit_note_row_data_list } from './customerCreditNotesSlice';
import { CreditNoteFilterAPICall, viewCreditNotesAPICall } from './customerCreditNotesAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"

export const CustomerCreditNotes = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")
  let navigate = useNavigate();
  const tableListData = useSelector((state) => state.customerCreditNoteSlice.apiResList);

  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages =  tableListData?.pages;
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredDatas, setFilteredData] = useState({});


  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  }

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Credit Notes");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Credit Notes"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Customer",
      "Invoice Date",
      "Invoice Reference",
      "Total",
      "Created By",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "accountingDate", width: 10 },
      { header: "Name", key: "seqName", width: 20 },
      { header: "Customer", key: "vendorName", width: 25 },
      { header: "Invoice Date", key: "invoiceDate", width: 25 },
      { header: "Invoice Reference", key: "invoiceRef", width: 25 },
      { header: "Total", key: "total", width: 15 },
      { header: "Created By", key: "createdBy", width: 15 },
      { header: "Credit Notes", key: "status", width: 20 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        accountingDate: item?.accountingDate,
        seqName: item.seqName,
        vendorName: item.vendorName,
        invoiceDate: item?.invoiceDate,
        invoiceRef: item?.invoiceRef,
        total: item?.total,
        createdBy: item?.createdBy,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Payments.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const createButton = () => {
    navigate("/userdashboard/accounts/customers/creditNotes/create")

  }
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(get_customer_credit_note_row_data_list({customerCreditNoteSingleData:row}))
    // navigate("/userdashboard/accounts/customers/creditNotes/create")
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/accounts/customers/creditNotes/SingleView")

  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };


  useEffect(() => {
    localStorage.removeItem("singleViewById")
    store.dispatch(get_customer_credit_note_row_data_list({ customerCreditNoteSingleData: undefined }))
    // if (userRole === "user") {
    //   viewCreditNotesAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewCreditNotesAPICall({}, setIsLoading)
    // }
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  CreditNoteFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage]);


  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
              <div>
                <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div> 
          <CategorySearchandFilter
            statusOptions={['DRAFT', 'POSTED']}
            onData={handleDataFromChild}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
          <button onClick={createButton} className='create-button-blue'>Create</button>
          </div>
        </div>
        {/* <div className='create-button-blue-container' style={{justifyContent:"flex-end"}}>
        <button onClick={createButton}  className='create-button-blue'>Create</button>
      </div> */}
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Credit Notes</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                <th>Invoice Date</th>
                <th>Invoice Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :


                tableListData?.list!==undefined && tableListData?.list?.length !==0 ?  tableListData?.list?.slice(0)?.map((r,i)=>(
                  <tr onClick={()=>handleClickRow(r)}>
                    <td>{convertDateFormat(r?.accountingDate)}</td>
                    <td>{r?.seqName}</td>
                    <td>{r?.vendorName}</td>
                    <td>{convertDateFormat(r?.invoiceDate)}</td>
                    <td>{r?.invoiceRef}</td>
                    <td>{r?.total || "---"}</td>
                    <td>{r?.createdBy || "---"}</td>
                    <td className='table-data-box'>
                      {r?.status === "DRAFT" ? (
                        <span className='draft'> DRAFT</span>
                      ) : r?.status==="POSTED"? (
                        <span className='post'> POST</span>
                      ) : (
                        <span>{r?.status}</span>
                      )}
                    </td>
                  </tr>
                ))
                :
                <tr>
                <td colSpan={8}>No Data</td>
              </tr>

              }

            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
