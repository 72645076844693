import { IconButton, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { GRNFilterAPICall, getGRNpoViewAPICall, viewGrnUpdatedListAPICall } from "./grnListingAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import store from "../../../../../Redux/store";
import { get_grn_list_of_purchase_order, get_grn_list_row_data_list } from "./grnListingSlice";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { Source } from "@mui/icons-material";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const GrnListing1 = () => {
  let navigateTo = useNavigate();
  const [path, setPath] = useState(false);
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const [filteredData, setFilteredData] = useState({});
  // const branchId = localStorage.getItem("branchId");
  const tableList = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.apiResList
  );
  const grnList = useSelector((state) => state.goodsReceivedNoteUpdatedSlice.grnViewList)
  console.log(grnList);

  const [tableData, setTableData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("Draft");
  const [filteredArray, setFilteredArray] = useState([]);
  // console.log(filteredArray)
  const [isLoading, setIsLoading] = useState(false);
  const branchId = localStorage.getItem("branchId");
  const GRNpoId = localStorage.getItem("singleViewByIdgrn")
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableList?.pages;

  console.log(
    GRNpoId
  );
  const [selectedHeading, setSelectedHeading] = useState("GRN");
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.removeItem("singleViewPoById");
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("sequence", row?.purchaseOrderName)
    store.dispatch(get_grn_list_row_data_list({ grnListSingleRowData: row }));
    // navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add");
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/SingleView")
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Goods Received note");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Goods Received note"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:H1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["No", "Name","Date","Branch","Location","Vendor","Created By","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "No", key: "No", width: 8 },
      { header: "Name", key: "name", width: 15 },
      { header: "Date", key: "date", width: 25 },
      { header: "Branch", key: "branchId", width: 15 },
      { header: "Location", key: "locationName", width: 25 },
      { header: "Vendor", key: "supplierName", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 15 },
      { header: "Goods Received Note", key: "status", width: 15 },
  
    ];
  
    filteredArray?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        No: index+1,
        name: item.name,
        date:convertDateFormat (item?.date),
        branchId: item?.branchId,
        locationName: item?.locationName,
        supplierName: item?.supplierName,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);
  
    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Goods Received Note.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
    if (GRNpoId !== null) {
      grnList !== undefined && setFilteredArray(grnList)
      console.log(GRNpoId);
      console.count()
      setPath(true)
    }
    else {
      // grnList!==undefined && setFilteredArray(tableList)

      setPath(false)
      localStorage.removeItem("singleViewById");
      // let filteredArray = [];

      // if (tableData !== undefined) {
      // if (selectCategory === "Draft") {
      //   filteredArray = tableList?.filter((obj) => obj.status === "Drafted");
      // } else if (selectCategory === "Posted") {
      //   filteredArray = tableList?.filter((obj) => obj.status === "Complete");
      // }

      // console.log("helloooo", filteredArray);
      GRNpoId === null && setFilteredArray(tableList?.list);
    }

  }, [grnList, tableList, selectCategory])

  console.log(GRNpoId);
  useEffect(() => {
    if (GRNpoId) {
      getGRNpoViewAPICall({ purchaseId: GRNpoId }, setIsLoading);

    } else {
     // viewGrnUpdatedListAPICall({ branchId: branchId === "null" ? null : branchId }, setIsLoading);

    }
  }, [GRNpoId]);

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };


  const handleBackpage = () => {
    // localStorage.removeItem("singleViewByIdgrn")
    navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  useEffect(() => {
    
    if (FormDataInfo.length !== 0) {
      
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  GRNFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo.location,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
   
  }, [FormDataInfo, currentPage]);


  console.log("tableee", isLoading);
  return (
    <>
      <div className="global-page-parent-container">
        <div className="global-white-bg-container">
          {path && (
            <div className="first-main-container-div">
              <div className="first-main-heading-div" id="headingDiv">
                <p
                  style={{
                    marginLeft: "14px",
                    color: selectedHeading === "Purchase Order" ? "black" : "gray",
                  }}
                  onClick={() => { changeColor("Purchase Order"); handleBackpage() }}
                >
                  Purchase Order
                </p>
                <p
                  style={{
                    color: selectedHeading === "GRN" ? "black" : "gray",
                  }}
                //onClick={() => changeColor("purchase Order GRN")}
                >
                  GRN
                </p>

              </div>
            </div>
          )}
          <div className="justify-space-between"
            style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
          >
             {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
              statusOptions={['Complete', 'Drafted', 'Returned']}
              onData={handleDataFromChild}
              isLocation={true}
              isStatus={true}
              setFormDataInfo={setFormDataInfo}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            /> 
          
          </div>
            </div>
            {/* {!path && <div className="global-single-input">
              <Select
                value={selectCategory}
                onChange={(e) => setSelectCategory(e.target.value)}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Posted">Posted</MenuItem>
              </Select>
            </div>} */}
          </div>
          <div
            className="create-button-blue-container"
          // style={{ border: "2px solid blue"}}
          >
            <h3>Goods Received Note</h3>
            {GRNpoId=== null &&(
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            )}

            {/* {!path && <div className="global-single-input">
              <Select
                value={selectCategory}
                onChange={(e) => setSelectCategory(e.target.value)}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Posted">Posted</MenuItem>
              </Select>
            </div>} */}
            {/* <button
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/GoodsReceivedNote/add"
                )
              }
              className="create-button-blue"
            >
              Create
            </button> */}
          </div>

          <div style={{ width: "65%" }} className="justify-end">
            {/* <div className="global-single-input">
              <Select
                value={selectCategory}
                onChange={(e) => setSelectCategory(e.target.value)}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Posted">Posted</MenuItem>
              </Select>
            </div> */}
            {/* <div className="search-box-container">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search Location..."
                                onChange={""}
                            />
                            <IconButton
                                className="icon-btn"
                                style={{ position: "absolute", right: "10px" }}
                            >
                                <i class="bi bi-search search-icon"></i>
                            </IconButton>
                        </div> */}
            {/* <button 
                        onClick={()=>navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add")}
                        className="btn btn-primary" style={{width:"30%",backgroundColor:"#ff7700"}}>Add GRN</button> */}
          </div>
        </div>

        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>No</th>
                {/* {selectCategory === "Posted" && <th>Name</th>} */}
                <th>Name</th>
                <th>Date</th>
                <th>Branch</th>
                {/* {selectCategory === "Posted" && <th>Location</th>} */}
                <th>Location</th>
                <th>Vendor</th>
                <th>Created By</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              {isLoading === true ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={9}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredArray?.length !== 0 ? (
                filteredArray
                  ?.slice(0)
                  
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)} key={i}>
                      <td>{i + 1}</td>
                      {/* {selectCategory === "Posted" && <td>{r?.name}</td>} */}
                      <td>{r?.name}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.branchId}</td>
                      {/* {selectCategory === "Posted" && (
                          <td>{r?.locationName}</td>
                        )} */}
                      <td>{r?.locationName}</td>
                      <td>{r?.supplierName}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFTED" 
                                         
                         ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "Returned" ? (
                          <span className="return">RETURN</span>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={selectCategory === "Posted" ? 9 : 7}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
