import React, { useEffect, useState } from 'react'

import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter'
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { SalesReportAPIcall } from './SalesReportApi';
import { SalesReport } from '../../Report/SalesReport/SalesReport';
import { getCurrentTime } from '../../../../Js/Date';
import { useNavigate } from 'react-router-dom';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import ReactToPrint from 'react-to-print';
import { IconButton, Tooltip } from '@mui/material';
import ExcelJS from "exceljs"

export const SalesNewReport = () => {
   let navigate = useNavigate();
   const [symbol, setSymbol] = useState(null);
   const [FormDataInfo, setFormDataInfo] = useState([])
   const filtereDataOptions = useSelector((state) => state.listCategory);
   const decimalPosition = localStorage.getItem("decimalPosition");
   const decimalPositionStock = localStorage.getItem("decimalPositionStock");
   const [selectedBranch, setSelectedBranch] = useState(null);
   const [filteredData,setFilteredData]=useState()
   const SalesReport = useSelector((state) => state?.salesReport?.salesReportlist)
    console.log(SalesReport);
   const currencyListUpdated = useSelector(
      (state) => state?.viewUpdatedCurrency?.value
   );
   const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue
   );
   const handleDataFromChild = (data) => {
      setFormDataInfo(data)
   };
   const handleBranchSelect = (selectedBranch) => {
      setSelectedBranch(selectedBranch); 
    
    };
    const handleFilterChange=(updatedItems)=>{
      setFilteredData(updatedItems)
    }
    const formatFilterInfo = (filters) => {
      const parts = [];
  
      if (filters?.fromDate && filters?.toDate) {
        parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
      }

  
      if (filters?.branchList) {
        parts.push(
          `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
        );
      }
  
      return parts; // Return the array directly
    };

   const downloadList = () => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Sales report");
      sheet.properties.defaultRowHeight = 20;
  
      // Add header section
      const titleRow = sheet.addRow(["sales report"]);
      titleRow.font = { size: 18, bold: true };
      sheet.mergeCells("A1:J1");
      titleRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
  
      // Add additional header information
      const filterParts = formatFilterInfo(filteredData) || [];
  
      // Ensure filterParts is an array and iterate over it
      if (Array.isArray(filterParts)) {
        filterParts.forEach((part, index) => {
          const row = sheet.addRow([part]);
          row.font = { size: 15, bold: true };
          sheet.mergeCells(`A${index + 2}:J${index + 2}`);
          row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
        });
      }
  
      // Add an empty row between header and table
      sheet.addRow([]);
  
      // Header row styling
      const headerRow = sheet.addRow([
        "Item",
        "UOM",
        "Opening Qty",
        "Sold Qty",
        "Return Qty",
        "Closing item",
        "Gross Item Sales",
        "Total Item Cost",
        "Item Profit",
        "Gross Profit Per Unit",
      ]);
      const headerFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF1d1da7" },
      };
      const headerFont = {
        size: 12,
        bold: true,
        color: { argb: "FFFFFFFF" }, // White font color
      };
  
      headerRow.eachCell((cell) => {
        cell.fill = headerFill;
        cell.font = headerFont;
        cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
      });
  
      sheet.columns = [
        { header: "Item", key: "itemName", width: 20 },
        { header: "UOM", key: "uom", width: 10 },
        { header: "Opening Qty", key: "openingQty", width: 20 },
        { header: "Sold Qty", key: "soldQty", width: 20 },
        { header: "Return Qty", key: "salesRet", width: 20 },
        { header: "Closing item", key: "closingSale", width: 20 },
        { header: "Gross Item Sales", key: "grossItemSale", width: 20 },
        { header: "Total Item Cost", key: "grossItemCost", width: 20 },
        { header: "Item Profit", key: "grossprofit", width: 20 },
        { header: "Sales Report", key: "grossprofitperunit", width: 20 },
      ];
  
      SalesReport?.forEach((item) => {
        const row = sheet.addRow({
           itemName: item.itemName,
           uom: item.uom,
           openingQty: ` ${item.openingQty?.toFixed(2)}`,
           soldQty: `${item?.soldQty?.toFixed(2)}`,
           salesRet: ` ${item?.salesRet?.toFixed(2)}`,
           closingSale: ` ${item?.closingSale?.toFixed(2)}`,
           grossItemSale: ` ${item?.grossItemSale?.toFixed(2)}`,
           grossItemCost: ` ${item?.grossItemCost?.toFixed(2)}`,
           grossprofit: ` ${item?.grossprofit?.toFixed(2)}`,
           grossprofitperunit: ` ${item?.grossprofitperunit?.toFixed(2)}`,
        });
  
        const rowFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
        };
  
        row.eachCell((cell, colNumber) => {
          cell.fill = rowFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if(colNumber=>1){
           cell.alignment={horizontal:"center"}
          }
         
        });
      });
  
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Sales Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };

   const handleClickRow=(row)=>{
      store.dispatch( setFilterActive(window.location.pathname))
      localStorage.setItem("singleViewById",row?._id)
     localStorage.setItem("fromDate", FormDataInfo?.fromDate);
     localStorage.setItem("toDate", FormDataInfo?.toDate);
     localStorage.setItem("account",row?.itemName)
     navigate("/userdashboard/sales/Report/salesReportSingleView")
   }

   useEffect(() => {
      let currencyObj = filterObjFromList(
         "_id",
         currencyListUpdated,
         "currency",
         companyProfileData
      );
      // console.log(currencyObj);
      setSymbol(currencyObj?.symbol);
   }, [companyProfileData, currencyListUpdated]);

   useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
   }, []);
   useEffect(() => {
      if(FormDataInfo?.length!==0){ let body ={
         fromDate:FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
         endDate:FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
         branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
         search:FormDataInfo?.search,
     }
     
     let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&  SalesReportAPIcall(body)
   }
     },[FormDataInfo])
     
   return (
      <div className="global-page-parent-container">
         <div className="global-white-bg-container">
            <div className='justify-space-between'>
               <div className="sharp-arrow" style={{ width: "105Px" }}> <p>Sales Report</p></div>
               <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <CategorySearchandFilter
                     statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                     onData={handleDataFromChild}
                     onBranchSelect={handleBranchSelect}
                  /> 
          </div>
            </div>

            <h1 style={{ margin: "1px", fontSize: "large" }}>Sales Report</h1>

            <div className='global-report-table-container'>
               <table>
                  <thead>
                     <tr>
                        <th>Item</th>
                        <th>UOM</th>
                        {/* <th>Opening Qty</th> */}
                        <th>Sold Qty</th>
                        <th>Return Qty</th>
                        <th>Closing item</th>
                        <th>Gross Item Sales</th>
                        <th>Total Item Cost</th>
                        <th>Item Profit</th>
                        <th>Gross Profit Per Unit</th>
                     </tr>
                  </thead>
                  <tbody>
                     {SalesReport?.map((item,index)=>(
                     <tr key={index} onClick={()=>handleClickRow(item)}>
                        <td>{item.itemName}</td>
                        <td>{item.uom}</td>
                        {/* <td>{item.openingQty?.toFixed(decimalPositionStock)}</td> */}
                        <td>{item.soldQty?.toFixed(decimalPositionStock)}</td>
                        <td>{item.salesRet?.toFixed(decimalPositionStock)}</td>
                        <td>{item.closingSale?.toFixed(decimalPosition)}</td>
                        <td>{item.grossItemSale?.toFixed(decimalPosition)}</td>
                        <td>{item.grossItemCost?.toFixed(decimalPosition)}</td>
                        <td>{item.grossprofit?.toFixed(decimalPosition)}</td>
                        <td>{item.grossprofitperunit?.toFixed(decimalPosition)}</td>
                     </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )
}
