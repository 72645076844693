import {
  Autocomplete,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png";
import {
  companyProfileInfoForUser,
  viewAllBranchesAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  filterObjFromList,
  roundNumericFields,
} from "../../../../../Js/generalFunctions";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import {
  filterUomByPurchseUomAPICall,
  filterUomByPurchseUomEditAPICall,
  viewUOMListAPICall,
  viewUOMSubListAPICall,
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { getAllNewProductList } from "../API/vendorBillAPI";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import { viewGlConfigAPICall } from "../../Configuration/glConfigAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { vieWareHouseLocationAPICall } from "../../../Inventory/Configuration/wareHouseLocationAPI";
import { vendorListApi, viewVendorListAPICall } from "../API/vendorListAPICall";
import {
  draftPurchaseWPOAPICall,
  editPurchaseWPOAPICall,
  generatePurchaseWPONameAPICall,
  postPurchaseWPOAPICall,
  purchaseWOPOHistoryAPICall,
  purchaseWoSoSingleViewApi,
  purchaseWpoRegisterPaymentPostAPICall,
} from "./purchaseWPOAPI";
import { productSearchApi } from "../../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { CreateProductList } from "../../../Inventory/Products/ProductListUpdated/CreateProductList";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { PurchaseHistory } from "./PurchaseHistory";
import purchasehistoryicon from "../../../../../Assets/SVG/Icon material-history.svg"
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";


export const CreatePurchaseWOPO1 = () => {
  let singleViewId = localStorage.getItem("singleViewById");
  const numToWords = require("num-words");
  // localStorage.setItem("decimalPosition",2)
  const dispatch = useDispatch();
  const decimalPosition = Number(localStorage.getItem("decimalPosition"));

  const editedData = useSelector((state) => state.purchaseWPOSlice.rowData);

  const customerList = useSelector(
    (state) => state.vendorsListSlice.apiResList
  );

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const UomList = useSelector(
    (state) => state.unitOfMeasurmentSlice.apiResList
  );
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const creditNoteNameList = useSelector(
    (state) => state.purchaseWPOSlice.generateName
  );

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  const accountsList = chartOfAccountList?.filter(
    (item) => item?.type === "Income"
  );

  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);

  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.userValue
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const journalSalesList = journalList?.filter(
    (item) => item?.type === "purchase"
  );
  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value
  );
  const filterUomByPurchase = useSelector(
    (state) => state.unitOfMeasurmentSlice.filterByPurchaseList
  );
  const allTaxListPurchaseType = allTaxList?.filter(
    (item) => item?.taxType === "Purchase" && item?.status === true
  );
  const pwpoHistoryData=useSelector((state)=>state.purchaseWPOSlice.history)

  const userLoginMeta = localStorage.getItem("login_meta");
  const userBranchId = JSON.parse(userLoginMeta)?.branchPk;
  const userRole = useSelector((state) => state.userRoleSlice.value);


  let navigateTo = useNavigate();
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [registerPayment, setRegisterPayment] = useState(false);
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [creditNoteName, setCreditNoteName] = useState("###");
  const [editDataId, setEditDataId] = useState("");
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");
  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
  });

  const tableDataInitial = {
    type: "lines",
    id: Date.now(),
    product: null,
    label: "",
    account: null,
    uom: null,
    qty: "",
    unitPrice: "",
    discount: "",
    tax: [],
    amount: null,
    inclusive: "",
    header: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allTaxListPurchaseType || [])
  );

  const [filteredUOMOptions, setFilteredUOMOptions] = useState(
    tableData?.map(() => filterUomByPurchase || [])
  );
  const [rowIndex, setRowIndex] = useState(null);

  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);

  const [addProductDialog, setAddProductDialog] = useState(false);

  const [logView, setLogView] = useState(false);
  const [isTableModified, setIsTableModified] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [openPurchaseHistory,setOpenPurchaseHistory]=useState(false)
  const [rowId,setRowId]=useState()
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const [loadingForm, setLoadingForm] = useState(false);
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const [log, setLog] = useState([]);

  const formInitialState = {
    customer: null,
    location: null,
    branch: null,
    invoiceReference: "",
    invoiceDate: today,
    paymentReference: "",
    accountingDate:today ,
    journal: null,
    currency: null,
    orderDate: null,

    customerNotes: "",
    termsAndConditions: "",

    isEdit: false,
    status: "",
    registerPaymentChequeDate: "",
    registerPaymentAmount: "",
    registerPaymentDate: today,
    paymentsDone: [],
    paymentType: null,
    type: "Manual",
    chequeNo: "",
    paymentTerms: null,
    lpoNo:"",

  };

  const [formValues, setFormValues] = useState(formInitialState);

  const clearForm = () => {
    setFormValues({
      ...formValues,
      customer: null,
      location: null,
      branch: null,
      invoiceReference: "",
      paymentReference: "",
      // accountingDate: null,
      // orderDate: "",
    });

    setTableData([tableDataInitial]);
    setIsTableModified(!isTableModified);
    setEditDataId("");
  };
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), header: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          label: "",
          account: null,
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll);
    // setAddRowInput(null);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([
      ...imageDisplay,
      { url: URL.createObjectURL(e.target.files[0]) },
    ]);
  };

  const removeImageFn = (i) => {
    // image.splice(i, 1);
    // imageDisplay.splice(i, 1);
    // setImage([...image]);
    // setImageDisplay([...imageDisplay]);
    if (editedData === undefined) {
      image.splice(i, 1);
    } else {
      image?.length !== 0 && image.splice(i - image?.length, 1);
      image.splice(i, 1);
    }

    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
    setImage([...image]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;

    if (key === "registerPaymentAmount") {
      if (editedData?.amountDue > value) {
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
      } else {
        setFormValues({
          ...formValues,
          [key]: value !== 0 ? editedData?.amountDue : newValue,
        });
      }
    } else if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  // handleInputChange
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          const selectedTaxId = filterObjFromList(
            "_id",
            allTaxList,
            "vendorTax",
            newValue
          )?.includedPrice;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListPurchaseType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListPurchaseType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          // filterUomByPurchseUomAPICall({ purchaseuom: newValue?.uom });

          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            uom: filterObjFromList("_id", uomSubList, "uom", newValue),
            qty: newValue !== null ? newValue?.stock : "",
            unitPrice: newValue?.cost || "",
            discount: newValue !== null ? 0 : "",
            tax:
              filterObjFromList("_id", allTaxList, "vendorTax", newValue) ===
              null
                ? []
                : [filterObjFromList("_id", allTaxList, "vendorTax", newValue)],
            account:
              newValue !== null
                ? filterObjFromList(
                    "_id",
                    accountsList,
                    "incomeAccount",
                    configurationList?.sales
                  )
                : null,
            amount:
              newValue !== null ? newValue?.cost * newValue?.stock : "",
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListPurchaseType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListPurchaseType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });

    setTableData(updatedData);
    setIsTableModified(!isTableModified);
  };

  const calculateAndSetCalculatedValues = () => {
    const calculatedValues = tableData?.map((row) => {
      if (row?.inclusive === true) {
        let totalAmt = Number(row?.qty) * Number(row?.unitPrice);
        let taxArray = row?.tax?.map((obj) => obj?.amount);

        let totalOfTax = taxArray.reduce((sum, item) => sum + item, 0);
        let taxableAmount = (totalAmt / (totalOfTax + 100)) * 100;

        let discount =
          (taxableAmount?.toFixed(decimalPosition) * row?.discount) / 100;

        let subtotalBeforeTax =
          taxableAmount?.toFixed(decimalPosition) -
          discount?.toFixed(decimalPosition);

        let totalDiscount = taxArray.reduce((total, taxPercentage) => {
          const discountAmount = (taxPercentage / 100) * subtotalBeforeTax;
          return total + discountAmount;
        }, 0);

        return { ...row, inclusiveTax: totalDiscount, exclusiveTax: 0 };
      } else if (row?.inclusive === false) {
        let totalAmt = Number(row?.qty) * Number(row?.unitPrice);

        let discount =
          (totalAmt?.toFixed(decimalPosition) * row?.discount) / 100;

        let subtotalBeforeTax =
          totalAmt?.toFixed(decimalPosition) -
          discount?.toFixed(decimalPosition);

        let totalTax = row?.tax?.reduce((sum, item) => sum + item?.amount, 0);

        let totalExclTax = (subtotalBeforeTax * totalTax) / 100;

        return { ...row, exclusiveTax: totalExclTax, inclusiveTax: 0 };
      } else {
        return { ...row, exclusiveTax: 0, inclusiveTax: 0 };
      }
    });
    setTableData(calculatedValues);
  };

  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
    ]);
    setTableData(updatedArray);
  };

  const tableDataToBackend = tableData?.filter((obj) => obj.product !== null)?.map((item) =>
    item?.type === "lines"
      ? {
          line_type: item?.type,
          _id: item?.product?._id,
          accountId: item?.account?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
          product: item?.product?.productName,
          label: item?.label,
          uomName: item?.uom?.uomName,
          purchaseUomName: item?.product?.purchaseUomName  || item?.purchaseUomName,
          purchaseuom: item?.product?.purchaseuom || item?.purchaseuom,
          cost: item?.product?.cost,
          uom: item?.uom?._id,
          qty: Number(item?.qty),
          unitPrice: Number(item?.unitPrice),
          discount: Number(item?.discount),
          includedPrice: item?.inclusive === "" ? null : item?.inclusive,
          // taxes:item?.tax
          taxes: item?.tax.length > 0 ? item.tax : null,
        }
      : {
          line_type: item?.type,
          label: item?.header,
        }
  );

  //clearRegisterForm
  const clearRegisterForm = () => {
    setFormValues({
      ...formValues,
      registerPaymentDate: "",
      paymentType: null,
      type: null,
      chequeNo: "",
      registerPaymentChequeDate: "",
      registerPaymentAmount: "",
    });
  };

  //cancel registered payment
  const registerPymentCancelBtn = () => {
    setRegisterPayment(false);
    clearRegisterForm();
  };

  const formData = new FormData();

  /*required*/ formData.append("vendor", formValues?.customer?._id);
  /*required*/ formData.append("branchId", formValues?.branch?._id);
  /*required*/ formData.append("location_id", formValues?.location?._id);
  formValues?.invoiceReference !== "" &&
    formData.append("billRef", formValues?.invoiceReference);
  /*required*/ formData.append("billDate", formValues?.invoiceDate);
  formValues?.paymentReference !== "" &&
    formData.append("paymentRef", formValues?.paymentReference);
  formValues?.accountingDate !== "" &&
    formData.append("accountingDate", formValues?.accountingDate);
  /*required*/ formData.append("journals", formValues?.journal?._id);
  /*required*/ formData.append("currency", formValues?.currency?._id);
  formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)
  formValues?.lpoNo!=="" && formData.append("LpoNo", formValues?.lpoNo);
 
  /*required*/ formData.append(
    "productInfo",
    JSON.stringify(tableDataToBackend)
  );
  // formValues?.orderDate!=="" && formData.append("dueDate",formValues?.orderDate)
  /*required*/ formData.append(
    "total",
    totalsInfo?.total?.toFixed(decimalPosition)
  );
  /*required*/ formData.append("discount", 0);
  /*required*/ formData.append("taxTotal", 0);
  image.length !== 0 &&
    image?.forEach((file, index) => {
      formData.append("file", file);
    });
  formValues?.termsAndConditions !== "" &&
    formData.append("termsAndCondition", formValues?.termsAndConditions);
  formValues?.customerNotes !== "" &&
    formData.append("customerNote", formValues?.customerNotes);
  /*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`);

  const submitBtnClicked = (key) => () => {
    dispatch( setFilterActive(false))
    if (key === "post") {
      let draftFn = () => {
        setFormValues({ ...formValues, isEdit: true, status: "POSTED" });
      };
      let postFormData = new FormData();
      postFormData.append("_id", editDataId);
      postFormData.append("postDate", `${today} ${getCurrentTime()}`);

      postPurchaseWPOAPICall(
        postFormData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
    if (formValues?.customer === null || formValues?.customer === undefined) {
      setValidationAlert({
        ...validationAlert,
        customer: "fill this field !!",
      });
    } else if (
      formValues?.branch === null ||
      formValues?.branch === undefined
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" });
    } else if (
      formValues?.location === null ||
      formValues?.location === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        location: "fill this field !!",
      });
    } else if (
      formValues?.invoiceDate === "" ||
      formValues?.invoiceDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        invoiceDate: "fill this field !!",
      });
    } else if (
      formValues?.accountingDate === "" ||
      formValues?.accountingDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        accountingDate: "fill this field !!",
      });
    } else if (
      formValues?.journal === null ||
      formValues?.journal === undefined
    ) {
      setValidationAlert({ ...validationAlert, journal: "fill this field !!" });
    } else if (
      formValues?.currency === null ||
      formValues?.currency === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        currency: "fill this field !!",
      });
    } else {
      if (key === "draft") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
        };
        draftPurchaseWPOAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setEditDataId,
          setLoadingForm
        );
      } else if (key === "edit") {
        formData.append("uploadUrl", JSON.stringify(image));
        formData.append("_id", editDataId);
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
          setIsEditClicked(false);
        };
        editPurchaseWPOAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setLoadingForm
        );
      } else if (key === "registerPaymentPost") {
        const fieldsToCheck = [
          "registerPaymentDate",
          "paymentType",
          "type",
          "chequeNo",
          "registerPaymentChequeDate",
          "registerPaymentAmount",
        ];
        const isFieldEmpty = (field) =>
          field === null || field === undefined || field === "";
        if (singleViewId) {
          let allFieldsValid = true;
          for (const fieldName of fieldsToCheck) {
            if (
              (fieldName === "registerPaymentChequeDate" &&
                formValues.type === "Cheque") ||
              fieldName !== "registerPaymentChequeDate"
            ) {
              if (isFieldEmpty(formValues[fieldName])) {
                setValidationAlert({
                  ...validationAlert,
                  [fieldName]: "fill this field !!",
                });
                allFieldsValid = false;
                break;
              }
            }
          }

          if (allFieldsValid) {
            let sendObj = {
              pwpoId: singleViewId,
              paymentTypeId: formValues?.paymentType?._id,
              chequeDate: formValues?.registerPaymentChequeDate,
              chequeNo: formValues?.chequeNo,
              type:
                formValues.type == "Manual"
                  ? 1
                  : formValues.type == "Cheque"
                  ? 2
                  : "",
              totalAmount: formValues?.registerPaymentAmount,
              paymentDate: formValues?.registerPaymentDate,
              amountInWords: numToWords(
                Math.floor(formValues?.registerPaymentAmount)
              ),
              postDate: `${today} ${getCurrentTime()}`,
            };

            purchaseWpoRegisterPaymentPostAPICall(
              sendObj,
              registerPymentCancelBtn,
              setSnackBarStates,
              setLoadingForm
            );
          }
        }
      }
    }
  };

  const openPurchaseHistoryFn=(item)=>{
    setRowId(item);
    setOpenPurchaseHistory(true)
  }

  useEffect(() => {
    calculateAndSetCalculatedValues();
  }, [isTableModified]);

  useEffect(() => {
    if (editedData === undefined && configurationList !== undefined) {
      setFormValues({
        ...formValues,
        journal: filterObjFromList(
          "_id",
          journalSalesList,
          "purchaseJournal",
          configurationList?.purchase
        ),
      });
    }
  }, [configurationList,journalList]);

  useEffect(() => {
    // viewVendorListAPICall();
    // vendorListApi();
    viewAllBranchesAPICall();
    journalListApiCall();
    // viewAllCurrencyUpdated({ status: 0 });
    viewMultiCurrencyConfiguration();
    companyProfileInfoForUser();
    chartOfAccountListApiCall();

    // apis for table
    // viewUOMListAPICall();
    getAllTaxSettingsListAPICall();

    // getAllNewProductList();
    // productSearchApi({search:""})
    viewUOMSubListAPICall();
    // viewGlConfigAPICall();

    if (singleViewId) {
      purchaseWoSoSingleViewApi({ _id: singleViewId });
    }
  }, []);

  // useEffect(() => {
  //   editedData === undefined && currencyListUpdated !== undefined &&
  //     setFormValues({
  //       ...formValues,
  //       currency: filterObjFromList(
  //         "_id",
  //         currencyListUpdated,
  //         "currency",
  //         companyProfileData
  //       ),
  //     });
  // }, [companyProfileData,currencyListUpdated]);
 

  useEffect(() => {
    setTotalsInfo({
      ...totalsInfo,
      subTotal: tableData?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      ),
      inclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.inclusiveTax || 0),
        0
      ),
      exclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.exclusiveTax || 0),
        0
      ),
      total:
        tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0) +
        tableData?.reduce(
          (sum, item) => sum + Number(item?.exclusiveTax || 0),
          0
        ),
    });
  }, [tableData]);

  useEffect(() => {
    if (editedData === undefined) {
      generatePurchaseWPONameAPICall({
        branchId: formValues?.branch?._id,
        invoiceDate: formValues?.invoiceDate,
      });
    }
  }, [formValues?.invoiceDate, formValues?.branch]);

  useEffect(() => {
    if (creditNoteNameList !== undefined) {
      setCreditNoteName(creditNoteNameList?.prefix);
    } else {
      setCreditNoteName("###");
    }
  }, [creditNoteNameList]);
  useEffect(() => {
    vieWareHouseLocationAPICall({ branchId: formValues?.branch?._id });
  }, [formValues?.branch]);



  // edit
  useEffect(() => {
    if (editedData !== undefined) {
      setImage([]);
      // isLoading === "" && setIsLoading(true);
      setEditDataId(editedData?._id);
      setCreditNoteName(editedData?.NAME);
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,

        location: filterObjFromList(
          "_id",
          wareHouseLocationList,
          "location_id",
          editedData
        ),

        customer: editedData?.vendor,
        branch: filterObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          editedData
        ),
        invoiceReference: editedData?.billRef,
        paymentReference: editedData?.paymentRef,
        journal: filterObjFromList(
          "_id",
          journalSalesList,
          "journals",
          editedData
        ),
        currency:
          MultiCurrencyView !== undefined &&
          MultiCurrencyView?.isMultiCurrency === true
            ? filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                editedData
              )
            : filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                companyProfileData
              ),
        accountingDate: editedData?.accountingDate,
        invoiceDate: editedData?.billDate,
        customerNotes: editedData?.customerNote,
        termsAndConditions: editedData?.termsAndCondition,
        amountDue: editedData?.amountDue,
        paymentsDone: editedData?.paymentsDone,
        paymentStatus: editedData?.paymentStatus,
        registerPaymentAmount: editedData?.amountDue,
        lpoNo:editedData?.LpoNo,

        paymentTerms:filterObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          editedData
        )
      });
   
      setLog(editedData?.log);
      //image
      setImageDisplay(
        editedData?.fileUrl?.map((r) => {
          return {
            url: r.url,
          };
        })
      );
      setImage(
        editedData?.fileUrl?.map((r) => {
          return {
            url: r?.url,
            _id: r?._id,
          };
        })
      );
    } else {
      // setFormValues(formInitialState)
      clearForm();
      let userBranch = {
        branchId: userBranchId,
      };

     
        setFormValues({
          ...formValues,
          branch:  allBranchesList !== undefined &&
          userRole === "user" ? filterObjFromList(
            "_id",
            allBranchesList,
            "branchId",
            userBranch
          ):null,
          currency: filterObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
          ),
        });

    }
  }, [
    editedData,
    allBranchesList,
    currencyListUpdated,
    companyProfileData
    // allNewProductList,
    // chartOfAccountList,
    // uomSubList,
    // wareHouseLocationList
  ]);

  useEffect(() => {
    if (editedData !== undefined) {
      const updatedArray = editedData?.productInfo?.map((r, i) => {
       
        if (r?.line_type === "Line" || r?.line_type === "lines") {
          return {
            type: r?.line_type,
            product: r?.prodObj,
            label: r?.label,
            account: filterObjFromList(
              "_id",
              chartOfAccountList,
              "accountId",
              r
            ),
            uom: filterObjFromList("_id", uomSubList, "uom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax: r?.taxes ? r?.taxes : [],
            amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100,
            inclusive: r?.includedPrice,
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });

      setTableData(updatedArray);
      setIsTableModified(!isTableModified);
    }
  }, [
    editedData?.productInfo,
    chartOfAccountList,
    uomSubList,
  ]);

  console.log(tableData);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);
  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  // useEffect(() => {
  //   if (filterUomByPurchase !== undefined) {
  //     filteredUOMOptions[rowIndex] = filterUomByPurchase;
  //   } else {
  //     filteredUOMOptions[rowIndex] = [];
  //   }
  // }, [filterUomByPurchase]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

  
  const filterOptions = createFilterOptions({
    stringify: (option) => option.productName + option.partNo,
  });
 


  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          ) : (
            <div className="new-global-white-bg-icon-container-left">
              <Tooltip
                title="Back"
                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      localStorage.removeItem("singleViewById");
                      navigateTo("/userdashboard/accounts/vendor/purchasewopo");
                    }
                  } else {
                    localStorage.removeItem("singleViewById");
                    navigateTo("/userdashboard/accounts/vendor/purchasewopo");
                  }
                }}
              >
                <IconButton>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip>
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? submitBtnClicked("edit")
                        : () => {
                            setFormValues({ ...formValues, isEdit: false });
                            setIsEditClicked(true);
                          }
                    }
                    onMouseEnter={handleMouseEnter}
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-check2-square edit-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                    {/* <i class="bi bi-check2-square edit-icon"></i> */}
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "" && (
                <Tooltip title="Save">
                  <IconButton
                    onMouseEnter={handleMouseEnter}
                    onClick={submitBtnClicked("draft")}
                  >
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "DRAFT" && !editClicked && (
                <button
                  onClick={submitBtnClicked("post")}
                  className="btn btn-post"
                >
                  Post
                </button>
              )}
              {formValues?.status === "POSTED" &&
                !formValues?.paymentStatus && (
                  <button
                    onClick={() => setRegisterPayment(true)}
                    style={{ width: "23%" }}
                    className="btn btn-primary"
                  >
                    Register Payment
                  </button>
                )}
            </div>
          )}
          <div className="status-container">
            {!isLoading && formValues?.status === "DRAFT" && (
              <img src={draftRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "POSTED" && (
              <img src={postRibbon} alt="" />
            )}
            {!isLoading &&
              formValues?.status === "PAID" &&
              formValues?.paymentStatus === true && <img src={paidRibbon} />}
          </div>
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">{!isLoading && creditNoteName}</h4>
        {isLoading ? (
          <Skeleton
            sx={{ margin: "1% 0" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={150}
          />
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => vendorListApi({ search: e.target.value })}
                    {...params}
                    label="Vendor*"
                    focused
                  />
                )}
                onChange={getFormInfo("customer")}
                value={formValues?.customer}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.customer}
              </p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                onChange={getFormInfo("branch")}
                value={formValues?.branch}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Location" focused />
                )}
                onChange={getFormInfo("location")}
                value={formValues.location}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.location}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Bill Refrence"
                variant="outlined"
                type="text"
                focused
                value={formValues?.invoiceReference}
                onChange={getFormInfo("invoiceReference")}
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.invoiceDate}
                onChange={getFormInfo("invoiceDate")}
                id="outlined-basic"
                label="Bill Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.invoiceDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.paymentReference}
                onChange={getFormInfo("paymentReference")}
                id="outlined-basic"
                label="Payment Reference"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.accountingDate||""}
                onChange={getFormInfo("accountingDate")}
                id="outlined-basic"
                label="Accounting Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.accountingDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={journalSalesList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Journal*" focused />
                )}
                value={formValues?.journal}
                onChange={getFormInfo("journal")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.journal}</p>
            </div>
            <div className="new-global-single-input">
              {MultiCurrencyView !== undefined &&
              MultiCurrencyView?.isMultiCurrency ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={currencyListUpdated || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency*" focused />
                  )}
                  value={formValues?.currency}
                  onChange={getFormInfo("currency")}
                  disabled={formValues?.isEdit}
                />
              ) : (
                <TextField
                  value={formValues?.currency?.name || ""}
                  sx={{ caretColor: "transparent" }}
                  className="disabled-input"
                  id="outlined-basic"
                  label="Currency*"
                  variant="outlined"
                  type="text"
                  focused
                  title="Multi Currency not enabled"
                  disabled={formValues?.isEdit}
                />
              )}
              <p className="doc-validation-alert">
                {validationAlert?.currency}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
          </div>
        )}
      </div>

      <div className="new-global-white-bg-container">
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div
              className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
              style={{ marginBottom: "1%" }}
            >
              <div
                className="tab-menu-button active"
                id="productMenuBtn"
                onClick={productMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Invoice Lines
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="departmentMenuBtn"
                onClick={departmentMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Other Info
                </Typography>
              </div>
            </div>
            {isActiveTable === "invoiceLines" ? (
              <>
                <div className="global-product-table">
                  <table disabled={formValues?.isEdit}>
                    <thead>
                      <tr>
                      <th className="justify-center" style={{gap:"6px"}}>Product 
                    <IconButton onClick={()=>setAddProductDialog(true)}>
                    <AddCircleIcon sx={{color:"blue"}}/>
                    </IconButton>
                    </th>
                        <th>Label</th>
                        {/* <th>Account</th> */}
                        <th>UOM</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Amount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableData?.length - 1
                              ? containerRef
                              : null
                          }
                        >
                          {item.type === "header" ? (
                            <>
                              <td colSpan="9">
                                <input
                                  type="text"
                                  value={item.header}
                                  onChange={handleInputChange(index, "header")}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          ) : (
                            <>
                              <td style={{ minWidth: "100px" }}>
                                <div className="product-table-auto-complete">

                                <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.productName}
                                disabled={formValues?.isEdit}
                                value={item?.product}
                                onChange={handleInputChange(index, "product")}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    />)}
                                    renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option.productName}</span>
                                  </li>
                                )}
                                filterOptions={filterOptions}

                                  />
                                </div>
                              </td>
                              <td style={{ maxWidth: "70px" }}>
                                 <div className="global-product-table-input-icon">
                                 {
                                    item?.product !== null && 
                                    <img onClick={()=>openPurchaseHistoryFn(item)} src={purchasehistoryicon} alt="" title="Purchase History"/>
                                    
                                  }
                                   <input
                                  type="text"
                                  onChange={handleInputChange(index, "label")}
                                  value={item?.label}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                                 </div>
                               
                              </td>
                              {/* <td style={{ minWidth: "120px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={accountsList || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) =>
                                      option?.accountName
                                    }
                                    disabled={formValues?.isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item?.account}
                                    onChange={handleInputChange(
                                      index,
                                      "account"
                                    )}
                                  />
                                </div>
                              </td> */}
                              <td style={{ minWidth: "95px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                    // options={filteredUOMOptions[index] || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) => option?.uomName}
                                    disabled={formValues?.isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item?.uom}
                                    onChange={handleInputChange(index, "uom")}
                                  />
                                </div>
                              </td>
                              <td style={{ maxWidth: "85px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(index, "qty")}
                                  value={item?.qty}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ maxWidth: "85px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "unitPrice"
                                  )}
                                  value={item?.unitPrice}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ maxWidth: "85px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "discount"
                                  )}
                                  value={item?.discount}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ width: "216px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    multiple
                                    options={filteredTaxOptions[index] || []}
                                    // style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        // onMouseEnter={handleMouseEnter}
                                        // onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item.tax}
                                    onChange={handleInputChange(index, "tax")}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td style={{ maxWidth: "90px" }}>
                                <input
                                  type="text"
                                  // onChange={handleInputChange(index, "amount")}
                                  defaultValue={item?.amount}
                                  style={{ textAlign: "right" }}
                                  disabled
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  value={item?.amount}
                                />
                              </td>
                              {
                                (formValues?.status === "" || editClicked )&&  
                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>}
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
               {
              (formValues?.status === "" || editClicked )&& 
               <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      disabled={formValues?.isEdit}
                    />
                  </div>
                  <button
                    disabled={formValues?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>}
              </>
            ) : (
              <div
                className="new-global-input-container"
                style={{ flexDirection: "column" }}
              >
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Order Date*"
                    variant="outlined"
                    type="date"
                    focused
                    disabled={formValues?.isEdit}
                    value={formValues?.orderDate}
                    onChange={getFormInfo("orderDate")}
                  />
                </div>

                <div className="global-product-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Partner</th>
                        <th>Employee</th>
                        <th>Branch</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ border: "0" }} colSpan={3}></td>
                        <td style={{ border: "0", textAlign: "end" }}>
                          Total :
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={110}
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" }}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                      {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                      {totalsInfo?.inclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
                    </div>
                  </div>
                  <hr
                    className="global-hr"
                    style={{ width: "80%", marginLeft: "25%" }}
                  />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
                    </div>
                  </div>
                  <div className="balanceAmountdiv">
                    {formValues?.paymentsDone &&
                      formValues?.paymentsDone.map((res, index) => (
                        <div className="paydiv" style={{ display: "flex" }}>
                          <p>{res?.name}</p>
                          <p>
                            {formValues?.currencyDetails?.symbol}
                            {res?.totalAmount}
                          </p>
                        </div>
                      ))}

                    {formValues?.status === "POSTED" &&
                      !formValues?.paymentStatus && (
                        <div className="paydiv">
                          <p>AmountDue</p>
                          <p> {formValues?.currencyDetails?.symbol}</p>
                          <p>{formValues?.amountDue}</p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <div className="file-picker-new">
                  <input
                    disabled={formValues?.isEdit}
                    type="file"
                    id="fileUpload"
                    onChange={changeImage}
                  />
                  <label htmlFor="fileUpload">
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {imageDisplay?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r?.url} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton
                          disabled={formValues?.isEdit}
                          onClick={() => removeImageFn(i)}
                        >
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {editedData !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      {/* register Payment dialog */}
      {/* <Dialog open={registerPayment} onClose={() => setRegisterPayment(false)}>
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div className="new-global-input-container">
            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.registerPaymentDate}
                onChange={getFormInfo("registerPaymentDate")}
              />
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "45%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
              />
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "47%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
              />
            </div>

            {formValues?.type === "Cheque" && (
              <>
                <div
                  className="new-global-single-input"
                  style={{ width: "45%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque No*"
                    variant="outlined"
                    type="text"
                    focused
                    value={formValues?.chequeNo}
                    onChange={getFormInfo("chequeNo")}
                  />
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "47%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.registerPaymentChequeDate}
                    onChange={getFormInfo("registerPaymentChequeDate")}
                  />
                </div>
              </>
            )}

            <div
              className="new-global-single-input"
              style={{ width: "45%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="number"
                focused
                value={formValues?.registerPaymentAmount}
                onChange={getFormInfo("registerPaymentAmount")}
              />
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button
              className="btn btn-primary"
              onClick={submitBtnClicked("registerPaymentPost")}
            >
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={registerPymentCancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog> */}
      <Dialog
        maxWidth="md"
        open={registerPayment}
        onClose={() => setRegisterPayment(false)}
      >
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div className="global-input-field-container">
            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.registerPaymentDate}
                onChange={getFormInfo("registerPaymentDate")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentDate}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "45%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Type*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "47%", padding: "6px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            <div
              className="new-global-single-input"
              style={{ width: "45%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label={
                  formValues?.type === "Cheque"
                    ? "Cheque No."
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={formValues?.chequeNo}
                onChange={getFormInfo("chequeNo")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>
            {formValues?.type === "Cheque" && (
              <>
                <div
                  className="new-global-single-input"
                  style={{ width: "47%", padding: "6px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.registerPaymentChequeDate}
                    onChange={getFormInfo("registerPaymentChequeDate")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.registerPaymentChequeDate}
                  </p>
                </div>
              </>
            )}

            <div
              className="new-global-single-input"
              style={{ width: "47%", padding: "6px" }}
            >
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="number"
                focused
                value={formValues?.registerPaymentAmount}
                onChange={getFormInfo("registerPaymentAmount")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.registerPaymentAmount}
              </p>
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button
              className="btn btn-primary"
              onClick={submitBtnClicked("registerPaymentPost")}
            >
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={registerPymentCancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={addProductDialog} maxWidth="auto" 
      // onClose={()=>setAddProductDialog(false)}
      >
        <div style={{width:"75vw",height:"80vh"}}>
              <CreateProductList productPopup={true} setAddProductDialog={setAddProductDialog} popUpLoading={setLoadingForm}/>
        </div>
      </Dialog>
      <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
      <LoadingForm loading={loadingForm} />
      <Dialog open={openPurchaseHistory} maxWidth="auto" >
         <div>
            <PurchaseHistory
               closeDialog={()=>setOpenPurchaseHistory(false)}
               item={rowId}
               historyApiCall={purchaseWOPOHistoryAPICall}
               name="Purchase wopo"
               response={pwpoHistoryData}
               orderno={creditNoteName}
               formValues={formValues?.customer}
               type="purchase"
            />
         </div>
      </Dialog>
    </div>
  );
};
