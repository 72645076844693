import {
  Autocomplete,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import salesOrderRibbon from "../../../../../Assets/statusRibbon/salesOrder.png";
import cancelRibbon from "../../../../../Assets/statusRibbon/cancel.png";
import doneRibbon from "../../../../../Assets/statusRibbon/done.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { filterObjFromList, roundNumericFields } from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { customerListApi, viewAllCustomersAPICall } from "../../../../../API/Customer/customerAPI";
import { viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterUomByPurchseUomAPICall, filterUomByPurchseUomEditAPICall, viewUOMListAPICall, viewUOMSubListAPICall } from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { getAllNewProductList } from "../../../Accounts/Vendor/API/vendorBillAPI";
import { confirmSalesOrderAPICall, draftSalesOrderAPICall, editSalesOrderAPICall, generateSalesOrderAPICall, generateSalesOrderNameAPICall, salesOrderHistoryAPICall, salesOrderSingleViewAPICall } from "./salesOrderAPI";
import { cancelSalesQuatationAPICall } from "../SalesQuotation/salesQuatationAPI";
import grn from "../../../../../Assets/SVG/grn.svg"
import vendor from "../../../../../Assets/SVG/vendor bill.svg"
import { productSearchApi } from "../../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { PurchaseHistory } from "../../../Accounts/Vendor/purchaseWPO/PurchaseHistory";
import purchasehistoryicon from "../../../../../Assets/SVG/Icon material-history.svg"
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";

export const CreateSalesOrder = () => {
 
  const singleRowId = localStorage.getItem("singleViewById")
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  const soHistoryData=useSelector((state)=>state.salesOrderSlice.history)
  // 
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")
  const userLoginMeta = localStorage.getItem('login_meta')
  const userBranchPk = JSON.parse(userLoginMeta)?.branchPk;
  const userEmployeeId = JSON.parse(userLoginMeta)?.data?._id;
  // setting decimal position a fixed value
  // localStorage.setItem("decimalPosition",2)
  const decimalPosition = Number(localStorage.getItem("decimalPosition"))

  // stored data from redux
  const editedData = useSelector((state) => state.salesOrderSlice.rowData)
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const allNewProductList = useSelector((state) => state.vendorBillSlice.NewProductList);

  const uomSubList = useSelector((state) => state.unitOfMeasurmentSlice.subUomList);
  const creditNoteNameList = useSelector((state) => state.salesOrderSlice.generateName)
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allTaxListSalesType = allTaxList?.filter((item) => item?.taxType === "Sales" && item?.status === true)
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);


  // defining to navigate to another page
  let navigateTo = useNavigate()

  // useStates
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const [creditNoteName, setCreditNoteName] = useState("###")
  const [editDataId, setEditDataId] = useState("")
  const [loadingForm,setLoadingForm]=useState(false)
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");


  // total container data
  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.00,
    inclusiveTax: 0.00,
    exclusiveTax: 0.00,
    total: 0.00
  })

  // table initial values
  const tableDataInitial = {
    type: "lines",
    product: null,
    label: "",
    account: null,
    uom: null,
    qty: "",
    unitPrice: "",
    discount: "",
    tax: [],
    amount: "",
    inclusive: "",
    header: ""
  }
  const [tableData, setTableData] = useState([
    tableDataInitial
  ]);
    const dispatch = useDispatch();

  // setting taxes list for each row 
  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allTaxListSalesType || [])
  );
  
  const [rowIndex, setRowIndex] = useState(null)
 
  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [logView, setLogView] = useState(false);
  const [isTableModified, setIsTableModified] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [log, setLog] = useState([])
  // snackbar states
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  // initial form input values
  const formInitialState = {
    customer: null,
    branch: null,
    employee: null,
    orderDate: today,
    reference: "",
    lpoNo:"",

    customerNotes: "",
    termsAndConditions: "",

    // otherInfo
    contactPerson: "",
    deliveryAddress: "",
    paymentTerms: null,

    isEdit: false,
    status: ""
  };

  const [formValues, setFormValues] = useState(formInitialState);

  const clearForm = () => {
    setFormValues({
      ...formValues,
      customer: null,
      reference: "",
      employee: null,
      customerNotes: "",
      termsAndConditions: "",

      // otherInfo
      contactPerson: "",
      deliveryAddress: "",
      paymentTerms: null,
    })

    setTableData([tableDataInitial])
    setIsTableModified(!isTableModified)
    setEditDataId("")
  }

  // switch between invoice lines and other info
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const [openPurchaseHistory,setOpenPurchaseHistory]=useState(false)
  const [rowId,setRowId]=useState()
  const [addRowScroll, setAddRowScroll] = useState(false)
  // to add new row in table
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", header: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          label: "",
          account: null,
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll)
  };
  // delete a row from the table
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  // upload file functions
  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };
  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  // view logs enabled
  const clickLogView = () => {
    setLogView(!logView);
  };
  // form info onchange fn
  // const getFormInfo = (key) => (e, newValue) => {
  //   const { value } = e.target;
  //   setValidationAlert({});
  //   setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
  // }; 
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  // table data onchnage for each rows
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          const selectedTaxId = filterObjFromList(
            "_id",
            allTaxListSalesType,
            "customerTax",
            newValue
          )?.includedPrice;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListSalesType?.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;

          // filterUomByPurchseUomAPICall({ purchaseuom: newValue?.uom });

          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            uom: filterObjFromList("_id", uomSubList, "uom", newValue),
            qty: newValue !== null ? 1 : "",
            unitPrice: newValue?.salesPrice || "",
            discount: newValue !== null ? 0 : "",
            tax:
              filterObjFromList("_id", allTaxList, "customerTax", newValue) ===
              null
                ? []
                : [
                    filterObjFromList(
                      "_id",
                      allTaxListSalesType,
                      "customerTax",
                      newValue
                    ),
                  ],
            amount: newValue?.salesPrice || "",
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListSalesType?.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          
          return { ...row, amount: totalAmt, [field]: value };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });

    setTableData(updatedData);
    setIsTableModified(!isTableModified);
  };

  // calculation of each row in the table
  const calculateAndSetCalculatedValues = () => {
    const calculatedValues = tableData?.map((row) => {
      if (row?.inclusive === true) {

        let totalAmt = Number(row?.qty) * Number(row?.unitPrice)
        let taxArray = row?.tax?.map((obj) => obj?.amount)

        let totalOfTax = taxArray.reduce((sum, item) => sum + item, 0)
        let taxableAmount = (totalAmt / (totalOfTax + 100)) * 100

        let discount = ((taxableAmount?.toFixed(decimalPosition)) * row?.discount) / 100

        let subtotalBeforeTax = (taxableAmount)?.toFixed(decimalPosition) - (discount?.toFixed(decimalPosition))


        let totalDiscount = taxArray.reduce((total, taxPercentage) => {
          const discountAmount = (taxPercentage / 100) * subtotalBeforeTax;
          return total + discountAmount;
        }, 0);

        return { ...row, inclusiveTax: totalDiscount, exclusiveTax: 0 };
      }
      else if (row?.inclusive === false) {

        let totalAmt = Number(row?.qty) * Number(row?.unitPrice)

        let discount = ((totalAmt?.toFixed(decimalPosition)) * row?.discount) / 100

        let subtotalBeforeTax = (totalAmt)?.toFixed(decimalPosition) - (discount?.toFixed(decimalPosition))

        let totalTax = row?.tax?.reduce((sum, item) => sum + item?.amount, 0)

        let totalExclTax = (subtotalBeforeTax * totalTax) / 100

        return { ...row, exclusiveTax: totalExclTax, inclusiveTax: 0 };
      }

      else {
        return { ...row, exclusiveTax: 0, inclusiveTax: 0 };
      }
    });
    setTableData(calculatedValues);
  };

  // functon used to set the decimalpositioning 
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, ["qty", "unitPrice", "discount", "amount"]);
    setTableData(updatedArray);
  }

  // setting product info array to send in request
  const tableDataToBackend = tableData?.filter((obj) => obj.product !== null)?.map((item) => (
    item?.type === "lines" ?
      {
        line_type: item?.type,
        _id: item?.product?._id,
        mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
        product: item?.product?.productName,
        label: item?.label,
        uomName: item?.uom?.uomName,
        purchaseUomName: item?.product?.purchaseUomName || item?.purchaseUomName,
        purchaseuom: item?.product?.purchaseuom || item?.purchaseuom,
        cost: item?.product?.cost,
        uom: item?.uom?._id,
        qty: Number(item?.qty),
        unitPrice: Number(item?.unitPrice),
        discount: Number(item?.discount),
        includedPrice: item?.inclusive === "" ? null : item?.inclusive,
        taxes: item?.tax
      } :
      {
        line_type: item?.type,
        label: item?.header
      }

  ))


  // creating formdata
  const formData = new FormData()
  formValues?.employee !== null && formData.append("salesPerson", formValues?.employee?._id)
// appending key and values
/*required*/ formData.append("customerId", formValues?.customer?._id)
/*required*/ formData.append("quotationDate", formValues?.orderDate)
// dummy data of expiry date given due to backend validation error
/*required*/ formData.append("expiryDate", today)
  formValues?.reference !== "" && formData.append("reference", formValues?.reference)
  formValues?.customerNotes !== "" && formData.append("customerNotes", formValues?.customerNotes)
  formValues?.termsAndConditions !== "" && formData.append("termsAndConditions", formValues?.termsAndConditions)
/*required*/ formData.append("total", (totalsInfo?.total)?.toFixed(decimalPosition))
/*required*/ formData.append("taxableAmount", 0)
/*required*/ formData.append("tax", 0)
/*required*/ formData.append("branchId", formValues?.branch?._id)
/*required*/ formData.append("productInfo", JSON.stringify(tableDataToBackend))
  image.length !== 0 && image.forEach((file, index) => { formData.append("addUrl", file) })
/*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`)
  formValues?.contactPerson !== "" && formData.append("contactPerson", formValues?.contactPerson)
  formValues?.deliveryAddress !== "" && formData.append("deliveryAddress", formValues?.deliveryAddress)
  formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)
  formValues?.lpoNo!=="" && formData.append("LpoNo", formValues?.lpoNo);

  // function to send api requests
  const submitBtnClicked = (key) => () => {
    dispatch( setFilterActive(false))
    if (key === "confirm") {
      let draftFn = () => {
        navigateTo("/userdashboard/sales/orders/deliveryNote/create")
      }

      let postFormData = {
        salesOrderId: editDataId,
        branchId: formValues?.branch?.storeCode,
        date: `${today} ${getCurrentTime()}`
      }

      confirmSalesOrderAPICall(postFormData, draftFn, setSnackBarStates, snackBarStates,setLoadingForm)
    }
    if (key === "cancel") {
      let draftFn = () => {
        setFormValues({ ...formValues, isEdit: true, status: "CANCEL" })
        setIsEditClicked(false)
      }
      let postFormData = {

        _id: editDataId,
        statusType: 1,
        postDate: `${today} ${getCurrentTime()}`

      }

      cancelSalesQuatationAPICall(postFormData, draftFn, setSnackBarStates, snackBarStates)
    }
    if (formValues?.customer === null || formValues?.customer === undefined) {
      setValidationAlert({ ...validationAlert, customer: "fill this field !!" })
    }
    else if (formValues?.branch === null || formValues?.branch === undefined) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" })
    }
    else if (formValues?.orderDate === "" || formValues?.orderDate === undefined) {
      setValidationAlert({ ...validationAlert, orderDate: "fill this field !!" })
    }
    else {

      if (key === "draft") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "SALES ORDER" })
        }
        draftSalesOrderAPICall(formData, draftFn, setSnackBarStates, snackBarStates, setEditDataId,setLoadingForm)

      }
      else if (key === "edit") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "SALES ORDER" })
          setIsEditClicked(false)
        }
        formData.append("_id", editDataId)
        editSalesOrderAPICall(formData, draftFn, setSnackBarStates, snackBarStates,setLoadingForm)
      }
    }

  }

  const openPurchaseHistoryFn=(item)=>{
    setRowId(item);
    setOpenPurchaseHistory(true)
  }



  // useEffects
  // for adding new row in table
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  // when table is modified calculate functions is called
  useEffect(() => {
    calculateAndSetCalculatedValues()
  }, [isTableModified])

  // initial api calls for listing dats
  useEffect(() => {
    // viewAllCustomersAPICall({ branchId: userRole === "admin" ? null : userBranchPk })
    // customerListApi()
    viewAllBranchesAPICall()
    getAllActiveEmployeesAPICall();
    // apis for table
    // viewUOMListAPICall();
    getAllTaxSettingsListAPICall();
    // getAllNewProductList();
    // productSearchApi({search:""})
    viewUOMSubListAPICall();
    if (singleRowId) {
      salesOrderSingleViewAPICall({ _id: singleRowId })
    }
  }, [])


  // calculation of totals
  useEffect(() => {
    setTotalsInfo({
      ...totalsInfo,
      subTotal: (tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0)),
      inclusiveTax: (tableData?.reduce((sum, item) => sum + Number(item?.inclusiveTax || 0), 0)),
      exclusiveTax: (tableData?.reduce((sum, item) => sum + Number(item?.exclusiveTax || 0), 0)),
      total: (tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0)) + (tableData?.reduce((sum, item) => sum + Number(item?.exclusiveTax || 0), 0))
    })
  }, [tableData])

  // generate name apicall
  useEffect(() => {
    // editedData===undefined && generateCreditNoteNameAPICall({branchId:formValues?.branch?.storeCode,invoiceDate:formValues?.invoiceDate})
    generateSalesOrderNameAPICall({ branchId: formValues?.branch?._id, quotationDate: formValues?.orderDate })
  }, [formValues?.orderDate, formValues?.branch])

  useEffect(() => {
    if (creditNoteNameList !== undefined) {
      setCreditNoteName(creditNoteNameList?.NAME)
    }
    else {
      setCreditNoteName("###")
    }
  }, [creditNoteNameList])

  // edit
  useEffect(() => {
    if (editedData !== undefined) {
      // isLoading === "" && setIsLoading(true)
      setEditDataId(editedData?._id)
      setCreditNoteName(editedData?.NAME)
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,
        employee: editedData?.employee,
        customer: editedData?.customer,
        branch: filterObjFromList("storeCode", allBranchesList, "branchId", editedData),
        orderDate: editedData?.quotationDate || "",
        reference: editedData?.reference || "",
        customerNotes: editedData?.customerNotes || "",
        termsAndConditions: editedData?.termsAndConditions || "",
        contactPerson: editedData?.contactPerson || "",
        deliveryAddress: editedData?.deliveryAddress || "",
        lpoNo:editedData?.LpoNo||"",

        paymentTerms:filterObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          editedData
        ),
        employee: filterObjFromList(
          "_id",
          allActiveEmpList,
          "employeeId",
          editedData
        ),
      })

      // const updatedArray=editedData?.productInfo?.map((r,i)=>(
      //   (r?.line_type==="Line"||r?.line_type==="lines")?
      //   {
      //     type:r?.line_type,
      //     product:filterObjFromList("_id",allNewProductList,"_id",r),
      //     label:r?.label ||"",
      //     uom:filterObjFromList("_id",uomSubList,"uom",r),
      //     qty:r?.qty ||"",
      //     unitPrice:r?.unitPrice ||"",
      //     discount:r?.discount ||"",
      //     tax:r?.taxes ||"",
      //     amount:(r?.qty*r?.unitPrice)-(((r?.qty*r?.unitPrice)*r?.discount)/100) ||"",
      //     inclusive:r?.includedPrice ||""
      //   }:{
      //     type:r?.line_type,
      //     header:r?.label,
      //   }
      // ))

      // setTableData(updatedArray)
      // setIsTableModified(!isTableModified)

      setLog(editedData?.log)
    } else {
      clearForm()
    }
  }, [editedData,
    customerList,
    allBranchesList,
    // allNewProductList,
    // uomSubList
  ])

  useEffect(() => {
    if (editedData !== undefined) {
      const updatedArray = editedData?.productInfo?.map((r, i) => {
        // filterUomByPurchseUomEditAPICall({ purchaseuom: r?.uom }, i, filteredUOMOptions)

        if (r?.line_type === "Line" || r?.line_type === "lines") {
          return {
            type: r?.line_type,
            product: r?.prodObj,
            label: r?.label || "",
            uom: filterObjFromList("_id", uomSubList, "uom", r),
            qty: r?.qty || "",
            unitPrice: r?.unitPrice || "",
            discount: r?.discount || "",
            tax: r?.taxes || "",
            amount: (r?.qty * r?.unitPrice) - (((r?.qty * r?.unitPrice) * r?.discount) / 100) || "",
            inclusive: r?.includedPrice
          }
        }
        else {
          return {
            type: r?.line_type,
            header: r?.label,
          }

        }
      })

      setTableData(updatedArray)
      setIsTableModified(!isTableModified)
    }
  }, [editedData?.productInfo, uomSubList])



  // setting default branch in user Case
  useEffect(() => {
    let userEmployee = {
      employeeId: userEmployeeId,
    };
    if (editedData === undefined) {
      let userBranch = {
        branchId: userBranchId
      }
      allBranchesList !== undefined && userRole === "user" && 
      setFormValues({ ...formValues,
         branch: filterObjFromList(
          "storeCode", 
          allBranchesList, 
          "branchId", 
          userBranch),
          employee: filterObjFromList(
            "_id",
            allActiveEmpList,
            "employeeId",
             userEmployee
          ),
         })
    }
  }, [userRole, allBranchesList,allActiveEmpList])



  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

 

  return (
    <div className="global-page-parent-container parent-container-log">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          {
            isLoading ?
              <Skeleton
                sx={{ marginTop: "1%" }}
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
              :
              <div className="new-global-white-bg-icon-container-left">
                <Tooltip title="Back" onClick={() => {

                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm("Changes you made may not be saved.");

                    if (userConfirmed) {
                      navigateTo("/userdashboard/sales/orders/salesOrder")
                      editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  }
                  else {
                    navigateTo("/userdashboard/sales/orders/salesOrder")
                    editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                }}>
                  <IconButton>
                    <i class="bi bi-chevron-left"></i>
                  </IconButton>
                </Tooltip>
                {
                  formValues?.status === "SALES ORDER" &&
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={editClicked ? submitBtnClicked("edit") :
                        () => {
                          setFormValues({ ...formValues, isEdit: false })
                          setIsEditClicked(true)
                        }}
                      onMouseEnter={handleMouseEnter}>
                      <i class={editClicked ? "bi bi-check2-square edit-icon" : "bi bi-pencil-square edit-icon1"}></i>
                    </IconButton>
                  </Tooltip>
                }

                {
                  formValues?.status === "" &&
                  <Tooltip title="Save">
                    <IconButton onMouseEnter={handleMouseEnter}
                      onClick={submitBtnClicked("draft")}
                    >
                      <i class="bi bi-floppy save-icon"></i>
                    </IconButton>
                  </Tooltip>
                }
                {
                  formValues?.status === "CANCEL" &&
                  <Tooltip title="Reset to Draft">
                    <IconButton
                    >
                      <i class="bi bi-floppy save-icon"></i>
                    </IconButton>
                  </Tooltip>
                }

                {
                  formValues?.status === "SALES ORDER" && !editClicked &&
                  <>
                    <button
                      className="btn btn-post"
                      style={{
                        color: "#131010",
                        backgroundColor: "white",
                        border: "2px solid black",
                      }}
                      onClick={submitBtnClicked("cancel")}
                    >
                      Cancel
                    </button>

                    <button className="btn btn-post"
                      onClick={submitBtnClicked("confirm")}
                    >
                      Confirm
                    </button>
                  </>
                }
              </div>}

          {editedData !== undefined && (
            <div className="po-btn-container">
              {/* <div className="po-debit-button">
            <img src={debit} alt={""}></img>
            <p>Debit Note</p>
            <p>00</p>
          </div> */}

              <div className="po-debit-button" style={{ backgroundColor: " #f7e0d8" }}>
                <img src={vendor} alt={""}></img>
                <p>Delivery note</p>
                <p>00</p>
              </div>


              <div className="po-debit-button" style={{ backgroundColor: " #cdf3e0" }}>
                <img src={grn} alt={""}></img>
                <p>Invoice</p>
                <p>00</p>
              </div>

            </div>
          )}




          <div className="status-container">
            {!isLoading && formValues?.status === "SALES ORDER" && <img src={salesOrderRibbon} alt="" />}
            {!isLoading && formValues?.status === "CANCEL" && <img src={cancelRibbon} alt="" />}
            {!isLoading && formValues?.status === "DONE" && <img src={doneRibbon} alt="" />}
          </div>
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">{!isLoading && creditNoteName}</h4>
        {
          isLoading ?
            <Skeleton
              sx={{ margin: "1% 0" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={150}
            />
            :
            <div className="new-global-input-container">
              <div className="new-global-single-input auto-complete-new">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={customerList || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                    onChange={(e) => customerListApi({ search: e.target.value })}
                    {...params}
                    label="Customer*"
                    focused
                  />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.name}</span>
                    </li>
                  )}
                  onChange={getFormInfo("customer")}
                  value={formValues?.customer}
                  disabled={formValues?.isEdit}
                />
                <p className="doc-validation-alert">{validationAlert?.customer}</p>
              </div>

              <div className="new-global-single-input">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allBranchesList || []}
                  getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Branch*" focused
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{`${option?.storeCode}-${option?.branchName}`}</span>
                    </li>
                  )}
                  onChange={getFormInfo("branch")}
                  value={formValues?.branch}
                  disabled={formValues?.isEdit}
                />
                <p className="doc-validation-alert">{validationAlert?.branch}</p>

              </div>
              <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allActiveEmpList || []}
                getOptionLabel={(option) => 
                  option?.staff_name
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sales Person" focused />
                )}
                onChange={getFormInfo("employee")}
                value={formValues?.employee}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.employee}</p>
            </div>

              <div className='new-global-single-input'>
                <TextField
                  value={formValues?.orderDate}
                  onChange={getFormInfo("orderDate")}
                  id="outlined-basic"
                  label="Order Date*"
                  variant="outlined"
                  type="date"
                  focused
                  disabled={formValues?.isEdit} />
                <p className="doc-validation-alert">{validationAlert?.orderDate}</p>
              </div>


              <div className='new-global-single-input'>
                <TextField
                  value={formValues?.reference}
                  onChange={getFormInfo("reference")}
                  id="outlined-basic"
                  label="Reference"
                  variant="outlined"
                  type="text"
                  focused
                  disabled={formValues?.isEdit} />
              </div>
              <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
            </div>}
      </div>

      <div className="new-global-white-bg-container">
        {
          isLoading ?
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={110}
            />
            : <>
              <div
                className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
                style={{ marginBottom: "1%" }}
              >
                <div
                  className="tab-menu-button active"
                  id="productMenuBtn"
                  onClick={productMenuClick}
                >
                  <Typography fontSize={"0.9rem"} fontWeight={600}>
                    Invoice Lines
                  </Typography>
                </div>
                <div
                  className="tab-menu-button"
                  id="departmentMenuBtn"
                  onClick={departmentMenuClick}
                >
                  <Typography fontSize={"0.9rem"} fontWeight={600}>
                    Other Info
                  </Typography>
                </div>
              </div>
              {isActiveTable === "invoiceLines" ? (
                <>
                  <div className="global-product-table">
                    <table disabled={formValues?.isEdit}>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Description</th>
                          <th>UOM</th>
                          <th>Qty</th>
                          <th>Unit Price</th>
                          <th>Discount</th>
                          <th>Tax</th>
                          <th>Amount</th>
                          <th style={{ backgroundColor: "#fff" }}></th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableData?.map((item, index) => (
                          <tr
                            key={index}
                            ref={index === tableData?.length - 1 ? containerRef : null}
                          >
                            {item.type === "header" ? (
                              <>
                                <td colSpan="8">
                                  <input type="text"
                                    value={item.header}
                                    onChange={handleInputChange(index, 'header')}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                    disabled={formValues?.isEdit}
                                  />
                                </td>
                                <td style={{ border: "none", width: "4%" }}>
                                  <IconButton
                                    onClick={() => handleDelete(index)}
                                    style={{ height: "30px" }}
                                    disabled={formValues?.isEdit}
                                  >
                                    <i
                                      className="bi bi-trash3"
                                      style={{ color: "#db0000", fontSize: "1.3rem" }}
                                    ></i>
                                  </IconButton>
                                </td>
                              </>
                            ) : (
                              <>
                                <td style={{ minWidth: "180px" }}>
                                  <div className="product-table-auto-complete">
                                  <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option)=>option?.productName}
                                    renderInput={(params) => (
                                      <TextField
                                        // inputRef={inputRef}
                                        // onChange={(e) =>
                                        //   productSearchApi({
                                        //     search: e.target.value,
                                        //   })
                                        // }
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props} key={option._id}>
                                        <span>{option.productName}</span>
                                      </li>
                                    )}
                                disabled={formValues?.isEdit}
                                value={item?.product}
                                onChange={handleInputChange(index, "product")}
                              />
                                  </div>
                                </td>
                                <td style={{ maxWidth: "180px" }}>
                                <div className="global-product-table-input-icon">
                                  {
                                    item?.product !== null && 
                                    <img onClick={()=>openPurchaseHistoryFn(item)} src={purchasehistoryicon} alt="" title="Sales History"/>
                                    
                                  } 
                                 <input
                                    type="text"
                                    onChange={handleInputChange(
                                      index,
                                      "label"
                                    )}
                                    value={item?.label}

                                    disabled={formValues?.isEdit}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                  </div>
                                 
                                </td>
                                <td style={{ minWidth: "95px" }}>
                                  <div className="product-table-auto-complete">
                                    <Autocomplete
                                      options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                      style={{ maxHeight: "100px" }}
                                      getOptionLabel={(option) => option?.uomName}
                                      disabled={formValues?.isEdit}
                                      renderInput={(params) => (
                                        <TextField {...params}

                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseEnter} />
                                      )}
                                      value={item?.uom}
                                      onChange={handleInputChange(index, "uom")}
                                    />
                                  </div>
                                </td>
                                <td style={{ maxWidth: "85px" }}>
                                  <input
                                    type="text"
                                    onChange={handleInputChange(index, "qty")}
                                    value={item?.qty}
                                    style={{ textAlign: "right" }}
                                    disabled={formValues?.isEdit}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                </td>
                                <td style={{ maxWidth: "85px" }}>
                                  <input
                                    type="text"
                                    style={{ textAlign: "right" }}
                                    onChange={handleInputChange(index, "unitPrice")}
                                    value={item?.unitPrice}

                                    disabled={formValues?.isEdit}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                </td>
                                <td style={{ maxWidth: "85px" }}>
                                  <input
                                    type="text"
                                    style={{ textAlign: "right" }}
                                    onChange={handleInputChange(index, "discount")}
                                    value={item?.discount}

                                    disabled={formValues?.isEdit}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                </td>
                                <td style={{ width: "216px" }}>
                                  <div className="product-table-auto-complete">
                                    <Autocomplete
                                      multiple
                                      options={filteredTaxOptions[index] || []}
                                      style={{ maxHeight: "100px" }}
                                      getOptionLabel={(option) => option?.taxName}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      value={item.tax}
                                      onChange={handleInputChange(index, "tax")}
                                      disabled={formValues?.isEdit}
                                    />
                                  </div>
                                </td>
                                <td style={{ maxWidth: "90px" }}>
                                  <input
                                    type="text"
                                    style={{ textAlign: "right" }}
                                    value={item?.amount}
                                    disabled={true}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                </td>
                                <td style={{ border: "none", width: "4%" }}>
                                  <IconButton
                                    onClick={() => handleDelete(index)}
                                    style={{ height: "30px" }}
                                    disabled={formValues?.isEdit}
                                  >
                                    <i
                                      className="bi bi-trash3"
                                      style={{ color: "#db0000", fontSize: "1.3rem" }}
                                    ></i>
                                  </IconButton>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="add-row-btn-container">
                    <div
                      className="global-single-input auto-complete add-line-autocomplete"
                      style={{ width: "76%" }}
                    >
                      <Autocomplete
                        options={addRowData || []}
                        getOptionLabel={(option) => option?.value}
                        renderInput={(params) => <TextField {...params} />}
                        value={addRowInput}
                        onChange={(e, newValue) => setAddRowInput(newValue)}
                        disabled={formValues?.isEdit}
                      />
                    </div>
                    <button
                      disabled={formValues?.isEdit}
                      onClick={() => addRow()} className="add-row-btn">
                      +
                    </button>
                  </div>
                </>
              ) : (
                <div className="new-global-input-container">
                  <div className="new-global-single-input">
                    <TextField
                      id="outlined-basic"
                      label="Contact Person"
                      variant="outlined"
                      type="text"
                      focused
                      disabled={formValues?.isEdit}
                      value={formValues?.contactPerson}
                      onChange={getFormInfo("contactPerson")}
                    />
                  </div>
                  <div className="new-global-single-input">
                    <TextField
                      id="outlined-basic"
                      label="Delivery Address"
                      variant="outlined"
                      type="text"
                      focused
                      disabled={formValues?.isEdit}
                      value={formValues?.deliveryAddress}
                      onChange={getFormInfo("deliveryAddress")}
                    />
                  </div>
                  {/* <div className="new-global-single-input">
                    <TextField
                      id="outlined-basic"
                      label="Payment Terms"
                      variant="outlined"
                      type="text"
                      focused
                      disabled={formValues?.isEdit}
                      value={formValues?.paymentTerms}
                      onChange={getFormInfo("paymentTerms")}
                    />
                  </div> */}
                </div>
              )}
            </>}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          {isLoading ?
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={110}
            />
            : <>
              <div style={{ display: "flex", justifyContent: "space-between", width: "97%" }}>
                <div className="new-global-single-input" style={{ width: "35%" }}>
                  <p>Customer Notes</p>
                  <textarea
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                    name="" id="" cols="50" rows="5"></textarea>
                </div>

                <div className="new-global-single-input" style={{ width: "35%" }}>
                  <p>Terms & Conditions</p>
                  <textarea
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                    name="" id="" cols="50" rows="5"></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" }}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {
                        totalsInfo?.inclusiveTax !== 0 &&
                        <p>Inclusive Tax</p>
                      }

                      {
                        totalsInfo?.exclusiveTax !== 0 &&
                        <p>Exclusive Tax</p>
                      }
                      {
                        totalsInfo?.exclusiveTax === 0 && totalsInfo?.inclusiveTax === 0 && <p>Tax</p>
                      }
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{(totalsInfo?.subTotal)?.toFixed(decimalPosition)}</p>
                      {
                        totalsInfo?.inclusiveTax !== 0 &&
                        <p>{(totalsInfo?.inclusiveTax)?.toFixed(decimalPosition)}</p>
                      }
                      {
                        totalsInfo?.exclusiveTax !== 0 &&
                        <p>{(totalsInfo?.exclusiveTax)?.toFixed(decimalPosition)}</p>
                      }
                      {
                        totalsInfo?.exclusiveTax === 0 && totalsInfo?.inclusiveTax === 0 && <p>0.00</p>
                      }
                    </div>
                  </div>
                  <hr className="global-hr" style={{ width: "80%", marginLeft: "25%" }} />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{(totalsInfo?.total)?.toFixed(decimalPosition)} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", width: "60%" }}
              >
                <div className="file-picker-new">
                  <input
                    disabled={formValues?.isEdit}
                    type="file"
                    id="fileUpload"
                    onChange={changeImage} />
                  <label htmlFor="fileUpload">
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {imageDisplay?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton disabled={formValues?.isEdit} onClick={() => removeImageFn(i)}>
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>}
        </div>
      )}

      {
        editedData !== undefined &&
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>}
        <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

    <LoadingForm loading={loadingForm}/>
    <Dialog open={openPurchaseHistory} maxWidth="auto" >
         <div>
            <PurchaseHistory
               closeDialog={()=>setOpenPurchaseHistory(false)}
               item={rowId}
               historyApiCall={salesOrderHistoryAPICall}
               name="Sales Order"
               response={soHistoryData}
               orderno={creditNoteName}
               type="sales"
               formValues={formValues?.customer}
            />
         </div>
      </Dialog>

    </div>
  );
};
