import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertArrayToExcel, filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { SalesReportBySalesPersonAPIcall } from './SalesReportApi';
import ReactToPrint from 'react-to-print';
// import Template from "./SalesReportBySalesPersonPrint";
import { useRef } from 'react';
import ExcelJS from "exceljs"

function SalesReportBySalesPerson() {
  let SalesbySalesReportPrint = useRef();
    let navigate = useNavigate();
    let location = useLocation()
  
    const currencyListUpdated = useSelector(
      (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
      (state) => state?.companyProfileSlice?.userValue)
  
    const SingleListData = useSelector((state) => state?.salesReport?.salesReportBySalesPerson)
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const [filteredData, setFilteredData] = useState({});
    const initialvalue = {
      label: "Daily Sales Report",
      companyLogo: "",
      companyName: "",
      companyAddress: "",
      companyContact: "",
    };
  
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [formValues, setFormValues] = useState(initialvalue);
  
    useEffect(() => {
      setFormValues({
        ...formValues,
  
        invoiceCount:SingleListData?.total?.invoiceCount || "0",
        invoiceAmount:SingleListData?.total?.invoiceAmount || "0",
        returnCount:SingleListData?.total?.returnCount || '0.00',
        returnAmount:SingleListData?.total?.returnAmount || '0.00',
        tax:SingleListData?.total?.tax || '0.00',
        amount:SingleListData?.total?.amount || '0.00',
        receivedAmount:SingleListData?.total?.receivedAmount || '0.00',
        creditAmount:SingleListData?.total?.creditAmount || '0.00',
       
  
        companyLogo: companyProfileData?.logo || "",
        companyName: companyProfileData?.companyName || "",
        companyAddress: companyProfileData?.companyAddress || "",
        companyContact: companyProfileData?.contactNumber || "",
      });
      setData(SingleListData?.report);
      // setTotal(totalPaidAmtKeys);
    }, [SingleListData, companyProfileData]);
 
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    const singleViewId = localStorage.getItem("customerReceiptSingleViewId")
  
    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");
  
    // const totalJournalKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.journal) : [];
    // console.log(totalJournalKeys, 'lll')
  
    const handlePageChange = (page) => {
  
      setCurrentPage(page);
    };
  
    // const handleClick = () => {
    //   navigate('/userdashboard/sales/Report/salesReportBySalesSingleView');
    // };
  //   const handleClick = (row) => {
  //     localStorage.setItem("SingleViewId", row?._id);
  //     navigate('/userdashboard/sales/Report/salesReportBySalesSingleView', { state: { customerName: row?.customerName } })
  //     // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
  // };
    const handleRowClick = (row) => {
      localStorage.setItem("SingleViewId", row?._id);
      navigate('/userdashboard/sales/Report/salesReportBySalesSingleView')
      // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
  };
    useEffect(() => {
  
      SalesReportBySalesPersonAPIcall()
    }, [])
    const handleFilterChange = (updatedItems) => {
      setFilteredData(updatedItems);
    };
    const formatFilterInfo = (filters) => {
      const parts = [];
  
      if (filters?.toDate) {
        parts.push(`As of ${filters?.toDate}`);
      }
  
      if (filters?.branchList) {
        parts.push(
          `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
        );
      }
  
      return parts;
    };
    const downloadList = () => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Sales Report By Sales Person");
      sheet.properties.defaultRowHeight = 20;
  
      // Add header section
      const titleRow = sheet.addRow(["Sales Report By Sales Person"]);
      titleRow.font = { size: 15, bold: true };
      sheet.mergeCells("A1:J1");
      titleRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
  
      // Add additional header information
      const filterParts = formatFilterInfo(filteredData) || [];
  
      // Ensure filterParts is an array and iterate over it
      if (Array.isArray(filterParts)) {
        filterParts.forEach((part, index) => {
          const row = sheet.addRow([part]);
          row.font = { size: 11, bold: true };
          sheet.mergeCells(`A${index + 2}:J${index + 2}`);
          row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
        });
      }
  
      // Add an empty row between header and table
      sheet.addRow([]);
  
      // Header row styling
      const headerRow = sheet.addRow([
        "EMPLOYEE ID",
        "EMPLOYEE",
        "INVOICE COUNT",
        "INVOICE AMOUNT",
        "SALES RETURN COUNT",
        "SALES RETURN AMOUNT",
        "TOTAL TAX",
        "TOTAL DISCOUNT",
        "RECEIVED AMOUNT",
        "Sales By Sales Person",
      ]);
      const headerFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
      };
      const headerFont = {
        size: 12,
        bold: true,
        color: { argb: "FF000000" }, // White font color
      };
  
      headerRow.eachCell((cell) => {
        cell.fill = headerFill;
        cell.font = headerFont;
        cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
      });
  
      sheet.columns = [
        { header: "EMPLOYEE ID", key: "emp_id", width: 20 },
        { header: "EMPLOYEE", key: "staff_name", width: 25 },
        { header: "INVOICE COUNT", key: "invoiceCount", width: 25 },
        { header: "INVOICE AMOUNT", key: "invoiceAmount", width: 25 },
        { header: "SALES RETURN COUNT", key: "returnCount", width: 25 },
        { header: "SALES RETURN AMOUNT", key: "returnAmount", width: 25 },
        { header: "TOTAL TAX", key: "tax", width: 25 },
        { header: "TOTAL DISCOUNT", key: "discount", width: 25 },
        { header: "RECEIVED AMOUNT", key: "receivedAmount", width: 25 },
        { header: "Sales By Sales Person", key: "creditAmount", width: 25 },
      ];
  
      SingleListData?.report?.forEach((item) => {
        const row = sheet.addRow({
          emp_id: item?.emp_id,
          staff_name: item.staff_name,
          invoiceCount: ` ${item?.invoiceCount}`,
          invoiceAmount: ` ${item?.invoiceAmount?.toFixed(2)}`,
          returnCount: ` ${item?.returnCount}`,
          returnAmount: ` ${item?.returnAmount?.toFixed(2)}`,
          tax: ` ${item?.tax?.toFixed(2)}`,
          discount: ` ${item?.discount?.toFixed(2)}`,
          receivedAmount: ` ${item?.receivedAmount?.toFixed(2)}`,
          creditAmount: ` ${item?.creditAmount?.toFixed(2)}`,
        });
  
        const rowFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
        };
  
        row.eachCell((cell, colNumber) => {
          cell.fill = rowFill;
          cell.border = {
            //   top: { style: "thin", color: { argb: "FF000000" } },
            //   left: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber > 1) {
            // Right align all columns except the first
            cell.alignment = { horizontal: "right" };
          }
        });
      });
  
      // Add row for totals
      const totalRow = sheet.addRow([
        "Total",
        "",
        ` ${SingleListData?.total?.invoiceCount.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.invoiceAmount?.toFixed(2)}`,
        ` ${SingleListData?.total?.returnCount?.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.returnAmount?.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.tax?.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.discount?.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.receivedAmount?.toFixed(2)}`,
        `${symbol} ${SingleListData?.total?.creditAmount?.toFixed(2)}`,
      ]);
  
      totalRow.eachCell((cell, colNumber) => {
        cell.font = { bold: true, color: { argb: "FF000000" } };
        cell.fill = headerFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          cell.alignment = { horizontal: "right" };
        }
      });
  
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Sales By Sales Person.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };
  
    useEffect(() => {
      let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
      setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])
    useEffect(() => {
      viewAllCurrencyUpdated({ status: 0 });
      companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          
        <div style={{marginLeft:"19px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => SalesbySalesReportPrint}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            // width: '100%'
          }}>


            <CategorySearchandFilter
              // statusOptions={['POSTED', 'DRAFT']}
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              isLocation={true}
              isCategory={true}
              isposCategory={true}
              isDate={false}
              onFilterChange={handleFilterChange}

            // isStatus={true}
            />
            {/* <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton> */}
          </div>

        </div>

        <div className="create-button-blue-container">
          <h3> Sales Report By Sales Person</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>

                <th>EMPLOYEE ID</th>
                <th>EMPLOYEE</th>
                <th>INVOICE COUNT</th>
                <th>INVOICE AMOUNT</th>
                <th>SALES RETURN COUNT</th>
                <th>SALES RETURN AMOUNT</th>
                <th>TOTAL TAX</th>
                <th>TOTAL DISCOUNT</th>
                <th>RECEIVED AMOUNT</th>
                <th>CREDIT AMOUNT</th>
              </tr>
            </thead>
            <tbody>

              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)?.reverse()
                  ?.map((r, i) => (
                    <tr key={i}  onClick={() => handleRowClick(r)}>
                      <td>{r?.emp_id}</td>
                      <td>{r?.staff_name}</td>
                      <td>{r?.invoiceCount}</td>
                      <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.invoiceAmount?.toFixed(decimalPosition)}</td>
                      <td>{r?.returnCount}</td>
                      <td style={{textAlign:"end"}}>{r?.returnAmount}</td>
                      <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}</td>
                      <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td>
                      <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.receivedAmount?.toFixed(decimalPosition)}</td>
                      <td style={{textAlign:"end"}}>{symbol}&nbsp;{r?.creditAmount?.toFixed(decimalPosition)}</td>

                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >
              {/* <tr>
                <th style={{ paddingLeft: '2%',textAlign: 'start', fontSize: '0.8rem'}} colSpan={2}>TOTAL</th>
                <th>60</th>
                <th>7500.00</th>
                <th>4</th>
                <th>5200.00</th>
                <th>960.00</th>
                <th>1800.00</th>
                <th>600.00</th>
                <th>900.00</th>
           
              </tr> */}
              <tr>
                <th style={{ paddingLeft: '2%',textAlign: 'start', fontSize: '0.8rem' }} colSpan={2}>TOTAL</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.invoiceCount}</th>
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.invoiceAmount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.returnCount}</th>
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.returnAmount?.toFixed(decimalPosition)}</th>
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.tax?.toFixed(decimalPosition)}</th>
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)||"0.00"}</th>
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.receivedAmount?.toFixed(decimalPosition)}</th>
             
                <th style={{textAlign:"end"}}>{symbol}&nbsp;{SingleListData?.total !== undefined && SingleListData?.total?.creditAmount?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* <div style={{ display: "none" }}>
        <div ref={(el) => (SalesbySalesReportPrint = el)}>
          <Template data={data} formValues={formValues} total={total} />
        </div>
      </div> */}
    </div>

  )
}

export default SalesReportBySalesPerson