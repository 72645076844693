
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

const TableListActionButtons = ({downloadClick,shareClick,PrintClick}) => {
  return (
    <div>
    <Tooltip title="Share">
      <IconButton
            onClick={shareClick}
      >
        <i
          class="bi bi-share"
          style={{
            backgroundColor: "#9797e9",
          }}
        ></i>
      </IconButton>
    </Tooltip>

    <Tooltip title="Print">
        <IconButton
            className="printer-div"
            style={{ padding: "1px" }}
            onClick={PrintClick}
        >
            <i style={{ backgroundColor: " #d787e7" }}
                class="bi bi-printer"
            ></i>
        </IconButton>
    </Tooltip>

    <Tooltip title="Download">
      <IconButton 
      onClick={downloadClick}
      style={{ paddingRight: "0px" }}
      >
        <i
          class="bi bi-arrow-down-circle"
          style={{backgroundColor: "rgb(255 118 133 / 69%)"}}
        ></i>
      </IconButton>
    </Tooltip>
  </div>
  )
}

export default TableListActionButtons