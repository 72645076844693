import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { convertArrayToExcel, filterObjFromList } from '../../../../Js/generalFunctions';
import { StockMoveAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./StockMovePrint"
import * as XLSX from "xlsx";
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import { setTableLoadingGlobal } from '../../../../Redux/Dashboard/mainDashBoardSlice';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import ExcelJS from "exceljs"

export const StockMove = () => {
  let paymentsPrintNew = useRef();
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");
  const [isLoading,setIsLoading] = useState(true)
  const [selectedBranch, setSelectedBranch] = useState(null);
  const stockMove = useSelector((state) => state?.ReportSlice?.StockMove)
  const {tableLoading} = useSelector((state) => state.mainDashBoardSlice);
  const [filteredData, setFilteredData] = useState({});
  // console.log(stockMove);

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const initialvalue = {
    label: "Stock Move",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }

  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  useEffect(() => {
    setFormValues({
      ...formValues,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(stockMove)
  }, [stockMove, companyProfileData])


  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("vendor", row?.productName)
    localStorage.setItem("stock_id", row?._id);
    store.dispatch(setTableLoadingGlobal(true))
    navigate("/userdashboard/inventory/reports/stockMove/singleView")
  
  }

  // console.log(stockMove?._id);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    companyProfileInfoForUser();
  }, []);

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.location) {
      parts.push(`Branch: ${filters?.location?.name}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Stock move report");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Stock move report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:H1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Item", "UOM", "Opening", "In","Out","Closing","Cost","Values"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Item", key: "productName", width: 30 },
      { header: "UOM", key: "uom", width: 10 },
      { header: "Opening", key: "openingBalance", width: 15 },
      { header: "In", key: "totalInDate", width: 13 },
      { header: "Out", key: "totalOutDate", width: 13 },
      { header: "Closing", key: "closingBalance", width: 13},
      { header: "Cost", key: "cost", width: 13 },
      { header: "Stock Move Report", key: "value", width: 15 },
    ];
  
    stockMove?.forEach((item) => {
      const row = sheet.addRow({
        productName: item?.productName,
        uom: item?.uom,
        openingBalance: ` ${item?.openingBalance}`,
        totalInDate: ` ${item?.totalInDate?.toFixed(2)}`,
        totalOutDate: ` ${item?.totalOutDate?.toFixed(2)}`,
        closingBalance: ` ${item?.closingBalance?.toFixed(2)}`,
        cost: ` ${item?.cost?.toFixed(2)}`,
        value: ` ${item?.value?.toFixed(2)}`,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >2) { // Right align all columns except the first
            cell.alignment = { horizontal: 'right' };
          }else{
            cell.alignment = { horizontal: 'center' };
          }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.totalInDate,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.totalOutDate,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.closingBalance,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.cost,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition)}`,
    
      
      
      
      
     
    ]);
  
    totalRow.eachCell((cell,colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >1) { // Right align all columns except the first
        cell.alignment = { horizontal: 'right' };
      }
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Stock Move Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      localStorage.setItem("branch", (FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0]))
      localStorage.setItem("location", FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0])
      localStorage.setItem("fromDate", FormDataInfo?.fromDate)
      localStorage.setItem("ToDate", FormDataInfo?.toDate)

      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0],
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],

        search: FormDataInfo?.search,
      }
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && StockMoveAPIcall(body,setIsLoading)
    }
  }, [FormDataInfo])



  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="justify-space-between" >
            <div className="sharp-arrow" style={{ width: "147Px" }}> <p>Stock Move Report</p></div>
            <div style={{ marginLeft: "19px" }}>
              <Tooltip title="Share">
                <IconButton>
                  <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

              <ReactToPrint
                trigger={() => (
                  <IconButton className="printer-div" style={{ padding: "1px" }}>
                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                  </IconButton>
                )}
                content={() => paymentsPrintNew}
              />

              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

            </div>
          </div>
          <div>
            <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
              onData={handleDataFromChild}
              isLocation={true}
              onBranchSelect={handleBranchSelect}
            />

          </div>
        </div>
        <h1 style={{ margin: "1px", fontSize: "large" }}>Stock Move Report</h1>

        <div className="global-report-table-container">
          <table id='table_data'>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {
              tableLoading?
                [...Array(15)].map((_,i)=>(
                  <tr>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                ))
              :
              stockMove!==undefined?
              stockMove?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={8}>No Data</td>
              </tr>
            }
            </tbody>
            {
            stockMove!==undefined &&  tableLoading===false &&
            <tfoot>
              <tr>
                <th colSpan={7}>Total</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum, item) => sum + item?.value, 0)?.toFixed(decimalPosition))}</th>
              </tr>
            </tfoot>}
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} />
        </div>
      </div>
      <LoadingForm loading={isLoading} />
    </div>
  )
}